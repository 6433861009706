


import '../Styles/Medyaf.css';
import React, {useState,useEffect,useContext} from 'react';
import logo from "../Logo.svg"
import { FaUserAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import gp from "../Images/GooglePlay.svg"
import as from "../Images/AppStore.svg"
import ag from "../Images/AppGallery.svg"
import vat from "../Images/vat.svg"
import { HiShoppingCart } from "react-icons/hi";
import { FaWallet } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import Footer from '../Components/Footer';
import CancelModal from '../Components/CancelModal';
import { singleOrder,cancelOrder,payAgain } from '../api';
import { useParams,useNavigate } from 'react-router-dom';
import { OrderContext } from '../Context/OrderContext';
import BeatLoader from "react-spinners/BeatLoader";
import {BsChevronDoubleDown} from "react-icons/bs";
import i1 from "../Images/Order/i1.svg"
import i2 from "../Images/Order/i2.svg"
import i3 from "../Images/Order/i3.svg"
import i4 from "../Images/Order/i4.svg"
import i5 from "../Images/Order/i5.svg"
import i6 from "../Images/Order/i6.svg"
import i7 from "../Images/Order/i7.svg"
import i8 from "../Images/Order/i8.svg"
function OrderProcess() {
  const{setOrder}=useContext(OrderContext);
  const history = useNavigate();
    const { t, i18n } = useTranslation();
    const { id } = useParams();
const [modal,setModal]=useState(false);
const [orderData,setOrderData]=useState();
const [status,setStatus]=useState();
const [loading,setLoading]=useState(true);
const [cancel,setCancel]=useState(true);
const [showPaymentStatus,setShowPaymentStatus]=useState(false);
const [payAg,setPayAgain]=useState(false);


//singleOrder api call takes order id from url and user token to fetch order data
//set order status
    useEffect(() => {
const token = localStorage.getItem("token");
localStorage.setItem("location","process"); 
      singleOrder(id,token).then((response) => {
console.log(response);
setStatus(response.data.data.statusId);
setOrderData(response.data.data);

 
if(response.data.data.statusId!==7 && response.data.data.statusId!==8 && response.data.data.statusId!==4){ //for displaying pay again button
  setPayAgain(true);
}

if(response.data.data.paymentMethod === "tap" || response.data.data.paymentMethod === "urway" ){
  setShowPaymentStatus(true);
}

setLoading(false);


      }).catch(error => {
        if (error.response.status === 401) {
      
           localStorage.removeItem("token");
           localStorage.removeItem("userPhone");
           localStorage.removeItem("userName");
           localStorage.removeItem("basket");
           history("/login")
        } else {
          
            console.log(error);
        }
    });
 },[]);


 //cancelOrder api takes order id and user token 
 //user is then redirected to his orders /profile/order (YourOrders component found in /Medyaf)
 const handleCancelOrder = () => {
  const token = localStorage.getItem("token");
  cancelOrder(id,token).then((response) => {
    setOrder(0);
    history("/profile/order");
    console.log(response);
  }).catch(error => {
    if (error.response.status === 401) {
  
       localStorage.removeItem("token");
       localStorage.removeItem("userPhone");
       localStorage.removeItem("userName");
       localStorage.removeItem("basket");
       history("/login")
    } else {
      
        console.log(error);
    }
});

};

//on clicking pay again , payAgain api takes order id and user token  then user is redirected to third party service
const handlePay = () => {
  const token = localStorage.getItem("token");
  
  payAgain(id,token).then((response) => {
   
    window.location.href = response.data.data.url;
  }).catch(error => {
    if (error.response.status === 401) {
  
       localStorage.removeItem("token");
       localStorage.removeItem("userPhone");
       localStorage.removeItem("userName");
       localStorage.removeItem("basket");
       history("/login")
    } else {
      
        console.log(error);
    }
});

};

    return (
      <div>
        {loading? <div className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
<div className='orderProcess'>

{modal? (
        <CancelModal setModal={setModal} handleCancelOrder={handleCancelOrder}/>
      ):null}



<div className='orderActions container-fluid fluid'>





{i18n.language=="ar"?



<div className="btn-group" role="group" aria-label="Basic example">
{status==7 || status==6?
<div className='cancelBtn cancelBtnAr' style={{textAlign:"right"}}>
 <button className={!payAg || !showPaymentStatus?'cnclBtn':null} onClick={()=>setModal(true)}>   {t("profile.cancel_request")} </button>


</div>: null }
  {

payAg && showPaymentStatus?
<div className='cancelBtn' style={{textAlign:"right"}}>
 <button className={!status==7 && !status==6?'payBtn2':'payBtnAr'} onClick={handlePay}>   {t("profile.pay_again")} </button>


</div>: null }
 
</div>

:

<div className="btn-group" role="group" aria-label="Basic example">
{status==7 || status==6?
<div className='cancelBtn' style={{textAlign:"right"}}>
 <button className={!payAg || !showPaymentStatus?'cnclBtn':null} onClick={()=>setModal(true)}>   {t("profile.cancel_request")} </button>


</div>: null }
  {
 
payAg && showPaymentStatus?
<div className='cancelBtn' style={{textAlign:"right"}}>
 <button className={!status==7 && !status==6?'payBtn2':'payBtn'} onClick={handlePay}>   {t("profile.pay_again")} </button>


</div>: null }
 
</div>}
</div>



<div className="cpTop ">
<div className={status== 7 ||status== 8 || status==4?'activeStep':null}>
<div className={i18n.language=="en"?"step1 ":"step1Ar "}> <div>
<svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.3657 17.9692C16.6802 18.6542 15.7704 19.0312 14.8027 19.0312C13.8349 19.0312 12.9251 18.6542 12.2397 17.9692L7.10486 12.8344C6.41986 12.1494 6.04285 11.2392 6.04285 10.2714C6.04285 9.30367 6.41986 8.39388 7.10486 7.70844C7.7903 7.02344 8.70009 6.64642 9.66785 6.64642C10.6356 6.64642 11.5458 7.02344 12.2308 7.70844L14.8027 10.2807L21.904 3.17896C19.5839 1.20184 16.5807 0 13.2911 0C5.95036 0 0 5.95036 0 13.2911C0 20.6318 5.95036 26.5822 13.2911 26.5822C20.6318 26.5822 26.5839 20.6318 26.5839 13.2911C26.5839 11.9171 26.3755 10.5922 25.9874 9.34526L17.3657 17.9692Z" fill="#C9C9C9"/>
<path d="M14.8028 16.6134C14.4931 16.6134 14.1846 16.4956 13.9492 16.2598L8.81439 11.125C8.34312 10.6537 8.34312 9.88905 8.81439 9.41601C9.28566 8.94297 10.0503 8.94474 10.5233 9.41601L14.8046 13.6972L26.9394 1.56244C27.4107 1.09117 28.1753 1.09117 28.6483 1.56244C29.1214 2.0337 29.1196 2.79835 28.6483 3.27139L15.6582 16.2616C15.421 16.4956 15.1108 16.6134 14.8028 16.6134Z" fill="#D9D9D9"/>
</svg>
  <p>{t("orderProcess.order_accepted")}</p>
  </div></div></div>


<BsChevronDoubleDown className='processDown' size={30} style={status== 8 || status== 4 || (status== 7 && showPaymentStatus)?{color:"#e47100;"}:{color:"#C9C9C9"}}/>

  <div className={status== 8 || status== 4 || (status== 7 && showPaymentStatus)?'activeStep':null}>
<div  className={i18n.language=="en"?"step  ":"stepAr"}> <div>
<svg className='delivered' xmlns="http://www.w3.org/2000/svg"  width="40" height="38" viewBox="0 0 108 111">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_2036" data-name="Rectangle 2036" width="108" height="111" transform="translate(1466 2008)" fill="#fff" stroke="#707070" strokeWidth="1"/>
    </clipPath>
  </defs>
  <g id="Mask_Group_44" data-name="Mask Group 44" transform="translate(-1466 -2008)" clipPath="url(#clip-path)">
    <path id="cooking" d="M101.551,4.447A2.6,2.6,0,1,1,105.234.764l.35.35A2.6,2.6,0,1,1,101.9,4.8Zm-.35,29a7.532,7.532,0,1,1-7.533,7.532A7.541,7.541,0,0,1,101.2,33.45Zm0,5.209a2.323,2.323,0,1,0,2.324,2.323A2.326,2.326,0,0,0,101.2,38.659ZM113,71.194v3.123a7.387,7.387,0,0,1-7.379,7.379h-2.17V95.738a11.21,11.21,0,0,1-11.2,11.2H33.409a11.21,11.21,0,0,1-11.2-11.2V81.7h-2.17a7.387,7.387,0,0,1-7.379-7.379V71.194a7.387,7.387,0,0,1,7.379-7.379h2.17v-7.7a2.6,2.6,0,0,1,2.6-2.6h76.032a2.6,2.6,0,0,1,2.6,2.6v7.7h2.17A7.387,7.387,0,0,1,113,71.194Zm-90.79-2.17h-2.17a2.172,2.172,0,0,0-2.17,2.17v3.123a2.172,2.172,0,0,0,2.17,2.17h2.17V69.024Zm76.032-10.3H27.421V95.738a6,6,0,0,0,5.988,5.988H92.256a6,6,0,0,0,5.988-5.988Zm9.549,12.471a2.172,2.172,0,0,0-2.17-2.17h-2.17v7.463h2.17a2.172,2.172,0,0,0,2.17-2.17V71.194ZM94.337,20.875a2.6,2.6,0,0,0,1.841-.762l.351-.35a2.6,2.6,0,0,0-3.682-3.685l-.351.35a2.6,2.6,0,0,0,1.841,4.447ZM72.749,48.364a2.6,2.6,0,0,0,5.209,0,3.731,3.731,0,1,1,7.462,0,2.6,2.6,0,1,0,5.209,0,8.94,8.94,0,1,0-17.881,0ZM24.1,44.465a2.6,2.6,0,0,1,1.935-3.134l28.3-6.69-.5-2.112a7.387,7.387,0,0,1,5.483-8.878l3.039-.718a7.388,7.388,0,0,1,8.879,5.483l.5,2.112,28.295-6.69a2.6,2.6,0,0,1,1.2,5.07L27.236,46.4A2.6,2.6,0,0,1,24.1,44.465ZM58.9,31.331l.5,2.112,7.263-1.717-.5-2.112A2.169,2.169,0,0,0,63.554,28l-3.039.719A2.173,2.173,0,0,0,58.9,31.331Z" transform="translate(1457.337 2010)" fill="#bebebe"/>
  </g>
</svg>
  <p>{t("orderProcess.order_InProgress")}</p>
  </div></div></div>
  <BsChevronDoubleDown className='processDown'  size={30} style={status==4?{color:"#e47100;"}:{color:"#C9C9C9"}}/>
 
  <div className={status==4?'activeStep':null}>
  <div className={i18n.language=="en"?"step4 ":"step4Ar "}>
  <div>
  <svg style={{marginTop:"0.5em"}}  id="Group_1688" data-name="Group 1688" xmlns="http://www.w3.org/2000/svg" width="106.638" height="30.425" viewBox="0 0 106.638 73.425">
  <g id="noun_meal_1556112" transform="translate(0 0)">
    <path id="Path_21" data-name="Path 21" d="M107.241,70.785a1.033,1.033,0,0,0,.01-.1c0-.03.01-.057.01-.089a2.466,2.466,0,0,0-.129-.758c-.012-.035-.022-.072-.035-.107a2.508,2.508,0,0,0-.452-.763c-.015-.017-.018-.037-.033-.054s-.023-.013-.033-.025a2.446,2.446,0,0,0-.731-.537.618.618,0,0,0-.065-.035,2.538,2.538,0,0,0-.853-.211c-.032,0-.059-.008-.089-.008s-.06-.01-.094-.01h-2.159c-1.213-22.511-18.634-40.929-41.149-44.519a8.606,8.606,0,0,0,1.194-4.319,8.695,8.695,0,0,0-17.389,0,8.576,8.576,0,0,0,1.116,4.173C23.382,26.629,5.479,45.252,4.249,68.084H3.135c-.032,0-.06.008-.092.01a.876.876,0,0,0-.1.01,2.47,2.47,0,0,0-.848.209c-.023.01-.043.025-.065.035a2.487,2.487,0,0,0-.733.54c-.008.008-.02.012-.03.022s-.017.035-.03.05a2.469,2.469,0,0,0-.455.768c-.013.035-.022.069-.033.105a2.5,2.5,0,0,0-.13.759c0,.03.008.059.008.089s.007.069.01.105a2.508,2.508,0,0,0,.207.84c.012.025.027.047.04.072a2.5,2.5,0,0,0,.535.726c.01.01.013.023.023.033L13.481,83.331c.017.017.04.02.059.035a2.548,2.548,0,0,0,.711.433c.03.012.06.018.09.028a2.441,2.441,0,0,0,.821.151h77.55a2.45,2.45,0,0,0,.823-.151c.03-.01.06-.017.092-.028a2.486,2.486,0,0,0,.709-.433c.018-.015.04-.02.059-.035l12.033-10.873c.01-.01.013-.023.025-.033a2.465,2.465,0,0,0,.534-.726.769.769,0,0,0,.04-.072A2.322,2.322,0,0,0,107.241,70.785ZM91.752,78.957H16.134L9.654,73.1H98.23ZM53.944,15.569a3.677,3.677,0,1,1-3.677,3.677A3.681,3.681,0,0,1,53.944,15.569Zm-.522,12.371c23.512,0,42.788,17.789,44.139,40.143H9.281C10.634,45.73,29.91,27.941,53.422,27.941Z" transform="translate(-0.623 -10.553)" fill="#bebebe"/>
    <path id="Path_22" data-name="Path 22" d="M20.867,29.727A39.511,39.511,0,0,0,9.54,45.5a2.509,2.509,0,0,0,4.677,1.82,34.5,34.5,0,0,1,9.889-13.76,2.509,2.509,0,0,0-3.238-3.834Z" transform="translate(5.26 1.947)" fill="#bebebe"/>
  </g>
  <g id="Group_214" data-name="Group 214" transform="translate(64.294 7.998)">
    <g id="Ellipse_7" data-name="Ellipse 7" transform="translate(0)" fill="#efefef" stroke="#bebebe" strokeWidth="4">
      <ellipse cx="21.171" cy="21.171" rx="21.171" ry="21.171" stroke="none"/>
      <ellipse cx="21.171" cy="21.171" rx="19.171" ry="19.171" fill="none"/>
    </g>
    <path id="Path_337" data-name="Path 337" d="M3906.412,1956.031l5.948,5.947,12.245-12.245" transform="translate(-3894.382 -1934.846)" fill="none" stroke="#bebebe" strokeLinecap="round" strokeWidth="4"/>
  </g>
</svg>

  <p> {t("orderProcess.done_order")}</p>
  </div>
</div></div>

</div>{/*
<div className='orderData container-fluid fluid'>
<table className="table  table-responsive-md tableHover">
  <thead>
    <tr>
     
      <th  scope="col">{t("party.youChoose")}</th>
      <th scope="col">{i18n.language=="ar"?orderData.mdiafMenuArName:orderData.mdiafMenuEnName}</th>
      <th scope="col">{t("mainMenu.num_persons")}</th>
      <th scope="col">{orderData.personsNumber}</th>
    </tr>
  </thead>
  <tbody>
  <tr>
      <th scope="row"></th>
    
      <td><FaRegCalendarAlt size={30} style={i18n.language=="ar"?{marginLeft:"0.5em",color:"#e47100"}:{marginRight:"0.5em",color:"#e47100"}}/>{orderData.date}</td>
      <td><FaRegClock size={30} style={{color:"#e47100"}}/> {orderData.time}</td>
      <th scope="col"></th>
    </tr>
    <tr>
      <th scope="row"></th>
    
      <td>{t("profile.order_id")}</td>
      <td>{orderData.id}</td>
      <th scope="col"></th>
    </tr>
    <tr>
      <th scope="row"></th>
    
      <td>{t("basket.address")}</td>
      <td>{orderData.address}</td>
      <th scope="col"></th>
    </tr>
    <tr>
      <th scope="row"></th>
      <td >{t("basket.notes")}</td>
      <td>{orderData.description}</td>
      <th scope="col"></th>
    </tr>
    <tr className='orderTotal'>
      <th style={i18n.language=="ar"?{textAlign:"right"}:null} colSpan={3}>{t("mainMenu.total_price")}</th>
      <td >{orderData.totalPrice} {t("nav.SAR")}</td>
    </tr>
  </tbody>
</table>

  </div>*/}

  <div style={{marginBottom:"5em"}} className='container-fluid fluid'>
  <p style={{color:" #331900"}} className="servicesTitle">{t("mainMenu.order_details")} </p>
<div className='orderDetails'>
<div className='container-fluid'>
 
  <div className='row'> 
  <div className='col-12'>
    <div className='dataRow'>
      <div className='th'>
        <img src={i1}/>
    <p className='title'>{t("profile.order_id")}</p></div>
    <p className='data'>{orderData.id}</p>


    </div>
    </div>
    <div className='col-12'>
    <div className='dataRow'>
    <div className='th'>
        <img src={i2}/>
<p className='title'>{t("mainMenu.menuName")}</p></div>
<p className='data'>{i18n.language=="ar"?orderData.mdiafMenuArName:orderData.mdiafMenuEnName}</p>
    </div>
    </div>
{orderData.services.length>0? 
    <div className='col-12'>
    <div className='dataRow'>
    <div className='th'>
        <img src={i2}/>
<p className='title'>{t("mainMenu.services")}</p></div>
<p className='data'>
{ orderData.services.map((data,index,array) => 
      <>
 { index<array.length-1?
 i18n.language=="ar"?
      data.arName + " - " :  data.enName + " - "  :
      i18n.language=="ar"?
      data.arName :  data.enName }  </>
      ) }  
  
  </p>
    </div>
    </div>:null}

    <div className='col-12'>
    <div className='dataRow'>
    <div className='th'>
        <img src={i3}/>
<p className='title'>{t("mainMenu.num_persons")}</p></div>
<p className='data'>{orderData.personsNumber}</p>
    </div>
    </div>
    <div className='col-12'>
    <div className='dataRow'>
    <div className='th'>
        <img src={i4}/>
<p className='title'>{t("mainMenu.date")}</p></div>
<p className='data'>{orderData.date}</p>
    </div>
    </div>
    <div className='col-12'>
    <div className='dataRow'>
    <div className='th'>
        <img src={i5}/>
<p className='title'>{t("mainMenu.time")}</p></div>
<p className='data'>{orderData.time}</p>
    </div>
    </div>
    <div className='col-12'>
    <div className='dataRow'>
    <div className='th'>
        <img src={i6}/>
<p className='title'>{t("basket.address")}</p></div>
<p className='data'>{orderData.address}</p>
    </div>
    </div>
    <div className='col-12'>
    <div className='dataRow'>
    <div className='th'>
        <img src={i7}/>
<p className='title'>{t("basket.notes")}</p></div>
<p className='data'>{orderData.description}</p>
    </div>
    </div>
    <div className='col-12'>
    <div className='dataRow'>
    <div className='th'>
        <img src={i8}/>
<p className='title'>{t("mainMenu.total_price")}</p></div>
<p className='data'>{orderData.totalPrice} {t("nav.SAR")}</p>
    </div>
    </div>
    </div>
  </div>


</div>
    </div>
  <Footer/>
  </div>}</div>
    );
}

export default OrderProcess;
